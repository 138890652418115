import { useState } from 'react';

function TailwindPlayground() {
    const [metadata, setMetadata] = useState<any>(null);
    const [file, setFile] = useState<any>(null);
    const [error, setError] = useState<any>(null);

    const onFilesSelected = (files: File[]) => {
        setFile(files[0]);

        playWithFile(files[0]).then(v => {
            setError(null);
            setMetadata(v);
        }).catch(e => {
            console.error(e);
            setError(e);
        });
    }

    const download = (range: any, filename: string) => {
        downloadBlobSlice(file, range[0], range[1], filename);
    }

    return (
        <div className="relative flex min-h-screen flex-col justify-center overflow-hidden bg-gray-50 py-6 sm:py-12" style={{ backgroundColor: '#222730' }}>
            <DropZone onFilesSelected={onFilesSelected} className={(file && metadata) ? "min-h-[90vh]" : ""}>
                <div className="divide-y divide-gray-300/50">
                    {!file && <div className="space-y-6 text-base leading-7 text-gray-600 text-center">
                        <p>Drop a <b>.safetensors</b> file here.</p>
                        <p className='text-gray-400 text-sm'>The file won't be uploaded, nor leave this page.</p>
                    </div>}

                    {file && metadata && <div className="pt-2 text-base font-semibold leading-7">
                        <DataExplorer data={metadata} filename={file.name} download={download} />
                    </div>}

                    {file && error && <div className="space-y-6 text-base leading-7 text-red-700">
                        <p><b>Error</b>: {error.message}</p>
                    </div>}
                </div>
            </DropZone>
        </div>
    );
}

const DropZone = (props: any) => {
    const { onFilesSelected, children, className, ...rest } = props;

    const [isDragging, setIsDragging] = useState(false);

    const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();

        setIsDragging(true);
    }

    const onDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();

        setIsDragging(true);
    }

    const onDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();

        setIsDragging(false);
    }

    const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();

        const files = e.dataTransfer.files;
        if (files.length === 0) {
            return;
        }

        onFilesSelected(files);

        setIsDragging(false);
    }

    return (
        <div className={"relative bg-white px-6 py-4 shadow-xl ring-1 ring-gray-900/5 container mx-auto max-w-2xl w-2xl max-h-[80vh] rounded-lg overflow-auto " + className} onDragEnter={onDragEnter} onDragOver={onDragOver} onDragLeave={onDragLeave} onDrop={onDrop}>
            {isDragging && <div className="mx-auto absolute inset-0 flex items-stretch justify-center bg-white/90" style={{pointerEvents: 'none'}}>
                <div className="divide-y divide-gray-300/50 self-center">
                    <div className="space-y-6 text-base leading-7 text-gray-600">
                        <p className="text-center">Drop it like it's hot!</p>
                    </div>
                </div>
            </div>}

            {children}
        </div>
    );
}

const DataExplorer = ({ data, filename, download }: { data: Record<string, any>, filename: string, download: any }) => {
    let tree = {};

    Object.keys(data).forEach(key => {
        // Keys are separated by dots. We restore the tree structure.
        const value = data[key]; // Ex.: add_embedding.linear_1.bias

        const parts = key.split('.');

        let current: any = tree;

        for (let i = 0; i < parts.length; i++) {
            const part = parts[i];
            const fullPath = parts.slice(0, i + 1).join('.');

            // Check if this is the last part
            if (i === parts.length - 1) {
                // Last part, assign the value
                const size = sizeOf(value);
                current[part] = { type: 'value', value, totalSize: size, path: fullPath };

                // Add the size of the value to the parent folder, walking up the tree
                let parent: any = tree;
                for (let i2 = 0; i2 < parts.length; i2++) {
                    const part = parts[i2];
                    parent.totalSize += size;

                    parent = parent[part];
                }
            } else {
                // Ensure the path exists
                current[part] ??= { type: 'folder', totalSize: 0, path: fullPath };

                // Move to the next level
                current = current[part];
            }
        }
    });

    return (<>
        <p className="ml-2 text-gray-700 font-heavy">
            {filename}
        </p>

        <div className="mt-1 text-sm space-y-4">
            <DataExplorerNode data={tree} depth={0} download={download} />
        </div>
    </>
    );
}

const DataExplorerNode = ({ data, depth, download }: { data: Record<string, any>, depth: number, download: any }) => {
    return (<ul className="ml-4 border-l border-gray-200">
        {Object.keys(data).map(key => {
            const value = data[key];

            if (value.type === 'folder') {
                return <DataExplorerFolder key={key} name={key} depth={depth} data={value} download={download} />;
            } else if (value.type === 'value') {
                return <DataExplorerValue key={key} name={key} depth={depth} obj={value} download={download} />;
            }
        })}
    </ul>);
}

const DTYPE_TO_BYTES = {
    'F32': 4,
    'F64': 8,
    'I32': 4,
    'I64': 8,
    'I8': 1,
    'U8': 1,
    'U16': 2,
    'U32': 4,
    'U64': 8,
    'Bool': 1,
    'BF16': 2,
    'F16': 2,
};

function sizeOf(value: any) {
    const numElements = value.shape ? value.shape.reduce((acc: number, v: number) => acc * v, 1) : 0;
    const byteSize = numElements * (DTYPE_TO_BYTES[value.dtype] ?? 0);

    return byteSize;
}

function formatSize(size: number) {
    let unit = 'bytes';

    if (size > 1024) {
        size /= 1024;
        unit = 'KB';
    }

    if (size > 1024) {
        size /= 1024;
        unit = 'MB';
    }

    if (size > 1024) {
        size /= 1024;
        unit = 'GB';
    }

    size = Math.round(size * 100) / 100;

    return { unit, size };
}

const DataExplorerValue = ({ name, depth, obj, download }: { name: string, depth: number, obj: any, download: any }) => {
    if (!obj.value || !obj.value.shape || !obj.value.dtype) {
        return null;
    }

    const value = obj.value;
    const numElements = value.shape ? value.shape.reduce((acc: number, v: number) => acc * v, 1) : 0;

    let { unit, size } = formatSize(obj.totalSize);

    const shapeForFilename = value.shape ? ('--' + value.shape.join('x')) : '';
    const filename = `${obj.path}${shapeForFilename}.${value.dtype.toLowerCase()}.bin`;

    return (
        <li>
            <div className="flex items-center space-x-2 ml-4">
                <span className="font-mono font-thin"> - {name}:</span>
                <span className="font-mono font-normal">{value.dtype} {'{'}{value.shape && value.shape.join(',')}{'}'}</span>
                <span className='font-light text-xs text-slate-700'> ({size} {unit}, {numElements} elem.)</span>

                {value.data_offsets && <button className='font-light text-xs text-sky-700 underline' title={filename} onClick={() => download(value.data_offsets, filename)}> extract</button>}
            </div>
        </li>
    );
}

const DataExplorerFolder = ({ name, depth, data, download }: { name: string, depth: number, data: any, download: any }) => {
    const [expanded, setExpanded] = useState(false);

    const { unit, size } = formatSize(data.totalSize);

    return (
        <li>
            <div className="flex items-center space-x-2 ml-3">
                <button onClick={() => setExpanded(v => !v)} className="flex items-center space-x-2">
                    <div className="flex-1 w-3 h-3 font-light flex items-center justify-center bg-gray-200 text-gray-600">
                        {expanded ? '-' : '+'}
                    </div>
                </button>

                <span className={"font-mono font-heavy"}>{name}</span>

                <span className='font-light text-xs text-slate-700'> ({size} {unit})</span>
            </div>

            {expanded && <DataExplorerNode data={data} depth={depth + 1} download={download} />}
        </li>
    );
}

async function playWithFile(file: File): Promise<any> {
    if (file.size < 8) {
        throw new Error('File is too small; safetensors header needs at least 8 bytes.');
    }

    const buffer = await file.slice(0, 8).arrayBuffer();

    // Read 8-byte unsigned long
    const view = new DataView(buffer);
    const size = view.getUint32(0, true);

    let meta: ArrayBuffer;

    try {
        meta = await file.slice(8, size + 8).arrayBuffer();
    } catch (e: any) {
        throw new Error('Failed to read metadata: ' + e.message);
    }

    if (meta.byteLength !== size) {
        throw new Error(`Metadata size mismatch: expected ${size} bytes, got ${meta.byteLength} bytes.`);
    }

    try {
        const metaString = new TextDecoder().decode(meta);
        const metadata = JSON.parse(metaString);

        return metadata;
    } catch (e: any) {
        throw new Error('Failed to parse metadata JSON: ' + e.message);
    }
}

function downloadBlobSlice(file: File, start, end, filename) {
    const blob = file.slice(start, end);
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = filename;
    // document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    // document.body.removeChild(a);

}

function debugBuffer(x: Uint8Array) {
    // Ascii print
    const ascii = String.fromCharCode(...x.map((v) => v & 0x7f).map((v) => (v | 0) < 32 ? '?'.charCodeAt(0) : v));
    console.log(ascii);

    // Hex editor print, like xxd
    let hexDigits: any[] = [];
    let stringRepresenation: any[] = [];

    for (let i = 0; i < x.length; i++) {
        const hex = x[i].toString(16).padStart(2, '0');
        hexDigits.push(hex);

        const char = x[i] < 32 ? '.' : String.fromCharCode(x[i]);
        stringRepresenation.push(char);
    }

    console.log('');
    console.log('          00 01 02 03 04 05 06 07 08 09 0a 0b 0c 0d 0e 0f   0123456789abcdef');
    for (let i = 0; i < hexDigits.length; i += 16) {
        const addr = i.toString(16).padStart(8, '0');
        console.log(addr + "  " + hexDigits.slice(i, i + 16).join(' '), ' ', stringRepresenation.slice(i, i + 16).join(''));
    }
    console.log('');
}

export default TailwindPlayground;
